
import { defineComponent, ref } from "vue"
import store from "@/store"
import AgencyAnalytics from "@/components/connections/AgencyAnalytics.vue"
import AddConnectionModal from "@/components/modals/AddConnectionModal.vue"
import { Modal } from "bootstrap"
import GoogleAnalytics from "@/components/connections/GoogleAnalytics.vue"

export default defineComponent({
  name: "connections",
  components: {
    AddConnectionModal,
    GoogleAnalytics,
    AgencyAnalytics
  },
  setup() {
    let accountNum
    let account
    let relationships
    let connectionInfo = [] as Record<string, any>[]
    let loading = ref<boolean>(false)
    let errors
    let googleAnalyticsConnection = {}
    let agencyAnalyticsConnection = {}

    return {
      googleAnalyticsConnection,
      agencyAnalyticsConnection,
      relationships,
      loading,
      connectionInfo,
      accountNum,
      account,
      errors
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    addConnectionModal() {

      const modalId = String("addNewConnection")
      const modalInstance = new Modal(document.getElementById(modalId))
      modalInstance.show()
    },
    async refreshData() {
      this.loading = true
      await this.init()
      this.loading = false
    },
    async init() {
      this.accountNum = store.getters.activeAccountInfo.id
      this.account = store.getters.activeAccountInfo.details
      this.relationships = store.getters.activeAccountInfo.relationships

      this.googleAnalyticsConnection = {
        type: "Google Analytics",
        analytics_email: this.account.analytics_email ?? "",
        analytics_ua: this.account.analytics_ua ?? "",
        analytics_view_id: this.account.analytics_view_id ?? "",
        analytics_dimension_filter: this.account.analytics_dimension_filter ?? "",
        property_id: this.account.property_id ?? ""
      }

      this.agencyAnalyticsConnection = this.relationships.agencyAnalytics

      // @TODO add Search Console when available
      // this.searchConsoleConnection = {
      //   type: "Search Console",
      //   serviceAccount: "service-account-3@hlm-api-connection24.iam.gserviceaccount.com"
      // }

    }
  }
});
