

import { defineComponent, ref } from "vue"
import { ErrorMessage, Field, Form } from "vee-validate"
import * as Yup from "yup"
import Swal from "sweetalert2/dist/sweetalert2.js"
import ApiService from "@/core/services/ApiService"
import store from "@/store"
import { Modal } from "bootstrap"
import { Mutations } from "@/store/enums/StoreEnums"
import { displayStatus } from "@/core/helpers/displayStatus"

export default defineComponent({
  name: "Add Connection Modal",
  props: {
    modalId: String,
  },
  emits: ['refreshData'],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    let submitAddAgencyAnalyticsRequest = ref<HTMLElement | null>(null)

    return { submitAddAgencyAnalyticsRequest }
  },
  data() {
    const addConnectionType = ''
    const accountNum = store.getters.activeAccountInfo.id
    const connectionTypes = [
      // @TODO add GA
      // {
      //   type: "ga", name: "Google Analytics"
      // },
      { type: "aa", name: "Agency Analytics" }
    ]


    const addAgencyAnalyticsFormValidator = Yup.object().shape({
      campaign_id: Yup.string().required().label("Campaign ID"),
      friendly_name: Yup.string().required().label("Friendly Name")
    })

    const agencyAnalytics = {
      campaign_id: '',
      friendly_name: ''
    }


    return {
      connectionTypes,
      addConnectionType,
      addAgencyAnalyticsFormValidator,
      agencyAnalytics,
      accountNum
    }
  },
  methods: {
    async addAgencyAnalytics() {

      if (this.submitAddAgencyAnalyticsRequest) {
        // Activate indicator
        this.submitAddAgencyAnalyticsRequest.setAttribute("data-kt-indicator", "on")

        let response = await this.sendRequest()

        if (response?.status == 201) {
          await Swal.fire({
            text: response.message,
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitAddAgencyAnalyticsRequest.removeAttribute("data-kt-indicator")
          await this.closeModal(this.$props.modalId)
          this.initForm()
          this.$emit("refreshData")
          await displayStatus(response.status_check)
        } else {
          await Swal.fire({
            text: response?.message,
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitAddAgencyAnalyticsRequest.removeAttribute("data-kt-indicator")
          await this.closeModal(this.$props.modalId)
        }

      }
    },
    async sendRequest() {

      ApiService.setHeader()

      let addAgencyAnalyticsResponse
      try {
        addAgencyAnalyticsResponse = await ApiService.post(`/accounts/${this.accountNum}/agency-analytics`, { data: this.agencyAnalytics })
      } catch (e) {
        console.error("Problem fetching update user response", e)
        return { message: "error adding agency analytics" }
      }

      if (addAgencyAnalyticsResponse.data) {

        store.commit(Mutations.UPDATE_ACTIVE_ACCOUNT_RELATIONSHIP, {
          name: "agencyAnalytics",
          data: { id: addAgencyAnalyticsResponse.data.data.id, ...addAgencyAnalyticsResponse.data.data.attributes }
        })

        return {
          status: addAgencyAnalyticsResponse.status,
          message: "created agency analytics successfully",
          status_check: addAgencyAnalyticsResponse.data.meta.status_check
        }

      }

    },
    async closeModal(id) {
      const modalId = String(id)
      const modalElement = document.getElementById(modalId)
      const modalInstance = Modal.getInstance(modalElement)
      modalInstance.hide()
    },
    initForm() {
      this.addConnectionType = ""
      this.agencyAnalytics = {
        campaign_id: '',
        friendly_name: ''
      }
    },
  },
});
